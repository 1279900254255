

/* for animation */
.container--fv {
  display: none;
  position: absolute;
  top: 0;
  background-color: transparent;
}
.container--fv.show {
  display: block;
  position: absolute;
  top: 0;
}


.container:not(.container--fv).hide {
  display: none;
  z-index: 2;
  animation-name: null;
  opacity: 0;
}
.container:not(.container--fv) {
  display: flex;
  animation-name: fadeIn;
  animation-duration: 2s;
  opacity: 0;
  animation-fill-mode: forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container--fv .fv__copy  {
  animation-duration: 3s;
  opacity: 0;
}
.container--fv .fv__copy01  {
  animation-name: fadeInDown1;
}
.container--fv .fv__copy02  {
  animation-name: fadeInDown2;
}
@keyframes fadeInDown1 {
  0% {
  transform: translateY(-40px);
    opacity: 0;
  }
  33% {
    opacity: 1;
    transform: translateY(0);
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInDown2 {
  0% {
  transform: translateY(-40px);
    opacity: 0;
  }
  22% {
    transform: translateY(-40px);
    opacity: 0;
  }
  55% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container--fv .fv__logo  {
  animation-name: fadeInLogo;
  animation-duration: 4s;
  opacity: 0;
}
@keyframes fadeInLogo {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.container--fv .bg.hide {
  animation-name: fadeOut;
  animation-duration: 4s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.main__section--mission .main__inner {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-delay: 1s;
  opacity: 0;
  animation-fill-mode: forwards;
}
